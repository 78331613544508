import * as React from 'react';
import { Link, graphql } from 'gatsby';
import { Box, Container, Flex, Stack, Icon } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import { FaEnvelope, FaFacebookF, FaLinkedinIn } from 'react-icons/fa';
import parse from 'html-react-parser';
import CustomButton from '../components/custom-button';
import SEO from '../components/seo';
import Layout from '../components/layout';
import '../styles/article.scss';

const Article = (props) => {
  // eslint-disable-next-line react/destructuring-assignment
  const post = props.data.wpArticle;
  return (
    <Layout>
      <SEO
        title="Enhanced Efficiency Fertilizers | Crystal Green | Ostara"
        description="Optimize plant nutrition by increasing uptake and reducing runoff with enhanced efficiency fertilizers designed for today's data-driven and environmentally aware growers."
        image="https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara_logo.png"
        keywords="Enhanced Efficiency Fertilizers"
      />
      <main className="article-page">
        <Box w="100%" p={4} className="article-hero-container">
          <Container maxW={1400}>
            <Box
              display="flex"
              flexDirection={['column', 'column', 'column', 'row']}
              justifyContent="space-between"
              paddingTop={[0, 0, 10, 50]}
            >
              <Box
                marginTop={[5, 5, 5, 0]}
                w={['100%', '100%', '100%', '46.5%']}
              >
                <Stack
                  className="article-hero-container--title"
                  maxW="50rem"
                  spacing={6}
                >
                  <Link to="/articles">Back</Link>
                  <h2>{post.title}</h2>
                  {parse(post.excerpt)}
                  <div className="articles-tags">Crystal Green Fertilizers</div>
                </Stack>
              </Box>
              <Stack
                spacing={[4, 6, 4, 6, 8]}
                w={['100%', '100%', '100%', '46.5%']}
              >
                <Box className="article-hero-img-container">
                  <StaticImage
                    className="hero-img"
                    placeholder="transparent"
                    src="../images/article-thumbnail.jpg"
                    alt="article-thumbnail"
                  />
                </Box>
              </Stack>
            </Box>
          </Container>
        </Box>
        <Box w="100%" className="article-body-container">
          <Flex direction="column">
            <Container
              marginTop={[10, 10, 10, 100]}
              marginBottom={[10, 10, 10, 100]}
              maxW={1400}
            >
              {parse(post.content)}
            </Container>
          </Flex>
        </Box>
        <Box w="100%" className="article-hero-image">
          <StaticImage
            placeholder="transparent"
            src="../images/article-hero-img.jpeg"
            alt="article-thumbnail"
          />
        </Box>
        <Box w="100%" className="article-body-container2">
          <Flex direction="column">
            <Container
              maxW="container.md"
              className="article-body-container2--wrapper"
            >
              <br />
              <p>
                Crystal Green is a trademark of Ostara Nutrient Recovery
                Technologies Inc
              </p>
              <br />
              <p>Contacts:</p>
              <p>Evoqua Water Technologies</p>
              <br />
              <p>Media</p>
              <p>Sarah Brown, 506-454-5495 (office)</p>
              <p>sarah.brown@evoqua.com</p>
              <br />
              <p>Investors Dan</p>
              <p>Brailer, 724-720-1605 (office)</p>
              <p>412-977-2605 (mobile)</p>
              <p>dan.brailer@evoqua.com</p>
              <br />
              <p>Ostara Nutrient Recovery Technologies Inc.</p>
              <br />
              <p>Matt Kuzma, 619-478-6969 (office)</p>
              <p>mkuzma@ostara.com</p>

              <hr />
              <div className="article-body-container2--footer">
                <div>
                  <p>Published 12.09.2021</p>
                  <p>Updated 12.09.2021</p>
                </div>
                <div className="share-container">
                  Share: <Icon as={FaFacebookF} />
                  <Icon as={FaLinkedinIn} />
                  <Icon as={FaEnvelope} />
                </div>
              </div>
            </Container>
          </Flex>
        </Box>

        <Box w="100%" className="article-body-container3">
          <Container maxW={1400} marginTop={20}>
            <Flex
              className="article-body-container3--heading"
              alignItems="center"
              justifyContent="space-between"
            >
              <h2>Related articles</h2>
              <CustomButton to="/articles" maxW={230}>
                VIEW ALL
              </CustomButton>
            </Flex>
            <Flex justifyContent="space-between" flexWrap="wrap">
              <div className="related-items">
                <StaticImage
                  src="../images/article-sample1.png"
                  alt="Crop Data Overlay img"
                  className="articles-thumbnail"
                />
                <h3>Title</h3>
                <p>
                  Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit, Sed
                  Do Eiusmod Tempor Incididunt Ut Labore Et Dolore Magna Aliqua.
                </p>
                <Link to="/articles">Read</Link>
              </div>
              <div className="related-items">
                <StaticImage
                  src="../images/article-sample2.png"
                  alt="Crop Data Overlay img"
                  className="articles-thumbnail"
                />
                <h3>Title</h3>
                <p>
                  Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit, Sed
                  Do Eiusmod Tempor Incididunt Ut Labore Et Dolore Magna Aliqua.
                </p>
                <Link to="/articles">Read</Link>
              </div>
              <div className="related-items">
                <StaticImage
                  src="../images/article-sample3.png"
                  alt="Crop Data Overlay img"
                  className="articles-thumbnail"
                />
                <h3>Title</h3>
                <p>
                  Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit, Sed
                  Do Eiusmod Tempor Incididunt Ut Labore Et Dolore Magna Aliqua.
                </p>
                <Link to="/articles">Read</Link>
              </div>
            </Flex>
          </Container>
        </Box>
      </main>
    </Layout>
  );
};

export default Article;
export const postQuery = graphql`
  query ($id: String) {
    wpArticle(id: { eq: $id }) {
      title
      content
      slug
      excerpt
      date(formatString: "DD MMMM YYYY")
      featuredImage {
        node {
          sourceUrl
        }
      }
    }
  }
`;
